import React, { useEffect } from "react" 
import { Link } from "@StarberryUtils";
import BookAViewForm from "../forms/book-a-viewing-form";
import ValuationFormPage from "../forms/valuation-form";
import OfficeBookAView from "../forms/office-details-form";
import ModalTiny from "react-bootstrap/Modal"
import { GetURL } from "../common/site/functions";
import "./StickyFooter.scss";
import { CloseIcon } from "../Icons/Icons";


// markup
const StickyFooter = (props) => { 

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
	const openBookViewformModal = () => {
	  setBookViewingformOpen(true);
	}
	const closeBookViewformModal = () => {
	  setBookViewingformOpen(false);
	}

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }
  const [modalOfficeBookViewformOpen, setOfficeBookViewingformOpen] = React.useState(false);
  const openOfficeBookViewformModal = () => {
	setOfficeBookViewingformOpen(true);
  }
  const closeOfficeBookViewformModal = () => {
	setOfficeBookViewingformOpen(false);
  }
  return (

    <React.Fragment>      
      <div className="sticky-wrapper d-md-none">								
								{props.cta_1 && props.search === "Book_Appointment" &&  !props.cta_1_link &&
									<button className="btn btn-primary contact-book-btn" onClick={openBookViewformModal}>{props.cta_1}</button>
								}
								{props.cta_1 && props.search === "Valuation_Form" &&  !props.cta_1_link &&
									<button className="btn btn-primary contact-book-btn" onClick={openValuationformModal}>{props.cta_1}</button>
								}
								{props.cta_1 && props.search === "Office_Request_Call_Back" &&  !props.cta_1_link &&
									<button className="btn btn-primary contact-book-btn" onClick={openOfficeBookViewformModal}>{props.cta_1}</button>
								}
								{props.cta_1 == "Get in touch" && props.cta_1_link && (
									<button className="btn btn-primary contact-book-btn" onClick={openOfficeBookViewformModal}>{props.cta_1}</button>
								)}
								{props.cta_1 != "Get in touch" && props.cta_1_link &&
									<Link to={`/${GetURL(props.cta_1_link.id)}`} className="btn btn-primary">{props?.cta_1} </Link>
								}
								{props.cta_2 && props.cta_2_link &&
									<Link to={`/${GetURL(props.cta_2_link.id)}`} className="btn btn-secondary">{props?.cta_2} </Link>
								}
								{props.cta_2 && props?.onCta2Click && !props.cta_2_link &&
									 <button className="btn btn-secondary mt-0 contact-book-btn" onClick={props.onCta2Click}>
									 		{props.cta_2}
								   	 </button>
								}
		</div>
              <ModalTiny show={modalBookViewformOpen} onHide={closeBookViewformModal} className="modal-form-wrapper form-viewing-wrapper">
				<ModalTiny.Header>
				<div class="modal-close-btn" onClick={closeBookViewformModal}><CloseIcon /></div>
				<ModalTiny.Title>
					<h4>Book an Appointment</h4>
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<BookAViewForm
					property_img={``}
					property_id={''}
					page_url={null}
					property_title={''}
					negotiator={''} />
				</ModalTiny.Body>
			</ModalTiny>    
			<ModalTiny show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form-wrapper form-valuation-wrapper">      
				<ModalTiny.Header>  
				<div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>        
				<ModalTiny.Title>
					<h4>Property Valuation</h4>
					<p className="text-sm">Receive a Professional Property Valuation in Minutes – Just Fill Out the Form</p> 
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<ValuationFormPage />
				</ModalTiny.Body>
			</ModalTiny>
			<ModalTiny show={modalOfficeBookViewformOpen} onHide={closeOfficeBookViewformModal} className="modal-form-wrapper form-valuation-wrapper">      
				<ModalTiny.Header>  
				<div class="modal-close-btn" onClick={closeOfficeBookViewformModal}><CloseIcon /></div>        
				<ModalTiny.Title>
				<h4>Get in touch</h4>
            	{/* <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec risus nec risus convallis aliquet lipsum.</p>  */}
         		</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<OfficeBookAView
					property_img={``}
					property_id={''}
					page_url={null}
					property_title={''}
					negotiator={''} />
				</ModalTiny.Body>
			</ModalTiny>
    </React.Fragment>
  )
}

export default StickyFooter