import React from 'react';
import { Container, Row } from "react-bootstrap";
import moment from 'moment';
import { Link } from 'gatsby';
import { LATEST_NEWS_PAGE_URL } from "../common/site/constants";
import PlayVideo from "../Play/custom-video";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const BlogListing = ({ news }) => {
  
  const featuredNews = news.filter(news => news.Featured === true);
  const newsList = news.filter(news => news.Featured === false);

  return (
    <section class="page-news-wrap">
                <Row className="featured-news-wrap news-list-wrap">
                    {featuredNews && featuredNews.map((news, index, active) => {
                      const image_url = news?.Tile_Image?.url 
                      const blogUrl = news.news_category?.URL 
                      ? `${LATEST_NEWS_PAGE_URL.alias}/${news.news_category?.URL}/${news?.URL}` 
                      : `${LATEST_NEWS_PAGE_URL.alias}/${news?.URL}`;                    

                      let processedImages = JSON.stringify({});
                      if (news?.imagetransforms?.Tile_Image_Transforms) {
                          processedImages = news.imagetransforms.Tile_Image_Transforms;
                      }
                        return (
                            <div className="col-sm-6 col-lg-4 col-xl-3 news-item featured-news-item">
                                <div className="news-img-wrapper img-zoom">
                                    <Link to={blogUrl}>
                                        <ImageTransform imagesources={image_url} renderer="srcSet" imagename="news-items.Tile_Image.tile_2" attr={{ alt: news.Title+ ' - Dacha', class:'' }} imagetransformresult={processedImages} id={news.id}/>
                                    </Link>
                                    {news.Embed_Video_URL &&
                                        <div className="banner-btn">
                                            <PlayVideo url={news.Embed_Video_URL}  
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="newsletter-info">
                                    {news.Date &&
                                     <div className="time-info text-xxs">
                                     <span><i class="icon-clock"></i></span><span>{moment(news.Date).format("DD/MM/YYYY")}</span>                                            
                                    </div>
                                    }
                                   
                                    <div className="news-title text-md-bold">
                                        <Link to={blogUrl}>
                                            {news.Title}

                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Row>
                <Row className="news-list-wrap">
                    {newsList && newsList.map((news, index, active) => {

                      const image_url = news?.Tile_Image?.url 
                      const blogUrl = news.news_category?.URL 
                      ? `${LATEST_NEWS_PAGE_URL.alias}/${news.news_category?.URL}/${news?.URL}` 
                      : `${LATEST_NEWS_PAGE_URL.alias}/${news?.URL}`;
                      let processedImages = JSON.stringify({});
                      if (news?.imagetransforms?.Tile_Image_Transforms) {
                          processedImages = news.imagetransforms.Tile_Image_Transforms;
                      }
                        return (
                            <div className="col-lg-3 col-md-6 news-item">
                                <div className="news-img-wrapper img-zoom">
                                    <Link  to={blogUrl}>
                                        <ImageTransform imagesources={image_url} renderer="srcSet" imagename="news-items.Tile_Image.tile_2" attr={{ alt: news.Title+ ' - Dacha', class:'' }} imagetransformresult={processedImages} id={news.id}/>
                                    </Link>
                                    {news.Embed_Video_URL &&
                                        <div className="banner-btn">
                                            <PlayVideo url={news.Embed_Video_URL}  
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="newsletter-info">
                                    <div className="time-info text-xxs">
                                        <span><i class="icon-clock"></i></span><span>{moment(news.Date).format("DD/MM/YYYY")}</span>                                            
                                    </div>
                                    <div className="news-title text-md-bold">
                                        <Link to={blogUrl}>
                                            {news.Title} 
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Row>

    </section>
)
}

export default BlogListing
